import {
  ModelUpdateAttributesObjectFields,
  ModelFields,
  ModelRecordFields,
  ModelRecord,
} from 'types/vendor';
import { Col, Row } from 'antd';
import { Button } from 'components/button/Button';
import { Modal, ModalHeader } from 'components/modal';
import { useModelApi } from 'context/model/useModelApi';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { modelSelectors } from 'store';
import { Status } from 'utils/types';
import { ScButtonsContainer, ScContainer, ScTable } from './MakeOptionalModal.styles';
import { useColumns } from './MakeOptionalModal.columns';
import {
  ACCESSORIES_PAGE_URL,
  OPTIONS_PAGE_URL,
  TERMS_AND_CONDITIONS_PAGE_URL,
} from 'common/constants';
import { get } from 'utils';

const MODAL_SETTINGS = {
  [ACCESSORIES_PAGE_URL]: {
    MODEL_TABLE: ModelFields.accessories,
    UPDATE_OBJECT_FIELDS: ModelUpdateAttributesObjectFields.commonItem,
  },
  [OPTIONS_PAGE_URL]: {
    MODEL_TABLE: ModelFields.options,
    UPDATE_OBJECT_FIELDS: ModelUpdateAttributesObjectFields.commonItem,
  },
  [TERMS_AND_CONDITIONS_PAGE_URL]: {
    MODEL_TABLE: ModelFields.termsAndConditions,
    UPDATE_OBJECT_FIELDS: ModelUpdateAttributesObjectFields.termsAndConditions,
  },
};

export type MakeOptionalModalCallParams = Pick<MakeOptionalModalProps, 'stepId'>;

export type MakeOptionalModalProps = {
  onClose(): void;
  stepId: keyof typeof MODAL_SETTINGS;
};

export const MakeOptionalModal: FC<MakeOptionalModalProps> = ({ onClose, stepId }) => {
  const { t } = useTranslation();
  const { updateModel } = useModelApi();
  const { sendMessage } = useStreaming();

  const { model, status } = useSelector(modelSelectors.getAll);
  const modelToken = useSelector(modelSelectors.getToken);

  const isLoading = useMemo(() => status === Status.Loading, [status]);

  const [selected, setSelected] = useState<string[]>([]);

  const { UPDATE_OBJECT_FIELDS, MODEL_TABLE } = MODAL_SETTINGS[stepId];

  const data = useMemo<ModelRecord[]>(() => {
    return get(model, [MODEL_TABLE], []) as ModelRecord[];
  }, [model, MODEL_TABLE]);

  useEffect(() => {
    setSelected(() =>
      data
        .filter(record => record[ModelRecordFields.optional])
        .map(record => record[ModelRecordFields.ID]),
    );
  }, [data]);

  const columns = useColumns();

  const handleSubmit = useCallback(async () => {
    if (!model) return;

    let modelToUse = model;

    const recordsToUpdate = data
      .filter(record => {
        const isOptional = record[ModelRecordFields.optional];
        const isOptionalNew = selected.includes(record[ModelRecordFields.ID]);

        return isOptional !== isOptionalNew;
      })
      .map(record => ({
        ...record,
        [ModelRecordFields.optional]: selected.includes(record[ModelRecordFields.ID]),
      }));

    const response = await updateModel(modelToUse, {
      [UPDATE_OBJECT_FIELDS]: recordsToUpdate,
    });

    if (response.response) modelToUse = response.response;

    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }

    onClose();
  }, [
    model,
    data,
    updateModel,
    UPDATE_OBJECT_FIELDS,
    modelToken,
    onClose,
    selected,
    sendMessage,
  ]);

  return (
    <Modal variant="md" visible onCancel={onClose}>
      <ModalHeader>{t('MODAL_MAKE_OPTIONAL__TITLE')}</ModalHeader>
      <ScContainer>
        <ScTable
          rowSelection={{
            hideSelectAll: false,
            selectedRowKeys: selected,
            onChange: selectedRowKeys => {
              setSelected(selectedRowKeys as string[]);
            },
            type: 'checkbox',
            getCheckboxProps() {
              return {
                disabled: isLoading,
              };
            },
          }}
          rowKey={ModelRecordFields.ID}
          columns={columns}
          data={data}
        />

        <ScButtonsContainer>
          <Row gutter={[10, { xs: 0, sm: 10, md: 0 }]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8, offset: 8 }}>
              <Button onClick={onClose} fullwidth>
                {t('MODAL_CLOSE')}
              </Button>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                fullwidth
                variant={'primary'}
              >
                {t('SAVE')}
              </Button>
            </Col>
          </Row>
        </ScButtonsContainer>
      </ScContainer>
    </Modal>
  );
};
