import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const ScRotatingLoader = styled(LoadingOutlined)`
  && {
    font-size: 2em;
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.dustyGray : theme.mainColor};
  }
`;

export const ScRotatingLoaderAbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
  }
`;
