import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FinancingDetails,
  FinancingDetailsFields,
  FinancingProduct,
  FinancingCalculation,
  FinancingListItemFields,
} from 'types/vendor';
import { CustomerFields } from 'types/vendor';
import { ScTabs } from './styles';
import Layout from 'layout/Default/Layout';
import Container from 'components/container/Container';
import { useSelector } from 'react-redux';
import { configurationSelectors, financingSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FINANCING_URL } from 'common/constants';
import { useRelations } from 'context/relations/RelationsProvider';
import { DEFAULT_COUNTRY_CODE } from '../../../utils/constants';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { useFeature } from 'context/feature/FeatureProvider';
import { useFinancingApi } from 'context/financing/useFinancingApi';
import CustomerForm from './Customer';
import CarInfoForm from './CarInfo';
import Total from './Total';
import { Status } from 'utils/types';
import { notification } from 'utils/notification';

const TabsKeys = {
  total: FinancingDetailsFields.total,
  carInfo: FinancingDetailsFields.carInfo,
  customer: FinancingDetailsFields.customer,
  calculation: FinancingDetailsFields.calculation,
};

export const FinancingForm: FC = () => {
  const { t } = useTranslation();
  const { id: financingId } = useParams<{ id: string }>();
  const history = useHistory();

  const { relations } = useRelations();
  const {
    getFinancingDetails,
    getFinancingSimulation,
    resetFinancingSimulation,
    saveFinancingSimulation,
    updateFinancingDetails,
    selectFinancingRecord,
    getFinancingList,
  } = useFinancingApi();

  const { isFeatureEnabled } = useFeature();
  const isFinancingCarInfoFormFeatureEnabled =
    isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowFinancingCarInfoForm,
    }) || true;
  const isFinancingListItemFormFeatureEnabled =
    isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowFinancingListItemForm,
    }) || true;
  const isFinancingCustomerFormFeatureEnabled =
    isFeatureEnabled({
      feature: GlobalFeaturesFlagsFields.allowFinancingCustomerForm,
    }) || true;

  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const {
    financingDetails,
    financingDetailsStatus,
    financingSimulation,
    financingSimulationStatus,
  } = useSelector(financingSelectors.getAll);

  // the one that is saved currently in db
  const [activeFinancingSimulation, setActiveFinancingSimulation] =
    useState<FinancingCalculation>();

  useEffect(() => {
    if (!financingId || !configurationNumber) return;
    getFinancingDetails(configurationNumber, financingId).then(({ response }) => {
      if (!response) {
        history.push(`${FINANCING_URL}/list`);
        return;
      }
      setActiveFinancingSimulation(response[FinancingDetailsFields.calculation]);
    });
  }, [configurationNumber, financingId, getFinancingDetails, history]);

  /** Used to:
   * prefill customer when creating new financing record;
   * have default country code for phone inputs;
   */
  const customer = useMemo(() => relations[0]?.customer, [relations]);

  const initialValues = useMemo<FinancingDetails | null | undefined>(() => {
    return financingDetails;
  }, [financingDetails]);

  const defaultCountryCode = useMemo<string>(
    () => customer?.[CustomerFields.country] ?? DEFAULT_COUNTRY_CODE,
    [customer],
  );

  const [activeTabKey, setActiveTabKey] = useState<string>(TabsKeys.customer);
  const [isActiveTabLocked, setIsActiveTabLocked] = useState<boolean>(false);

  const onTabChange = useCallback(
    (key: string) => {
      if (isActiveTabLocked && !window.confirm(t('NOT_SAVED'))) {
        return;
      }
      setIsActiveTabLocked(false);
      setActiveTabKey(key);
    },
    [isActiveTabLocked, t],
  );

  useEffect(() => {
    return resetFinancingSimulation;
  }, [resetFinancingSimulation]);

  const handleSubmit = useCallback(
    async (values: Partial<FinancingDetails>): Promise<void> => {
      if (!configurationNumber) return;

      if (initialValues) {
        const { status, messageHandled } = await updateFinancingDetails(
          configurationNumber,
          initialValues,
          values,
        );

        if (!messageHandled) {
          notification.openByStatus(status, {
            [Status.Success]: t('FINANCING_UPDATE_SUCCESS'),
            [Status.Error]: t('FINANCING_UPDATE_ERROR'),
          });
        }

        await getFinancingDetails(configurationNumber, financingId);
      }
    },
    [
      configurationNumber,
      financingId,
      getFinancingDetails,
      initialValues,
      t,
      updateFinancingDetails,
    ],
  );

  const onProductChange = useCallback(
    async (productKey: string) => {
      if (!configurationNumber) return;
      await getFinancingSimulation(configurationNumber, financingId, productKey, {});
    },
    [configurationNumber, financingId, getFinancingSimulation],
  );

  const onUpdateSimulation = useCallback(
    async (productKey: string, values: Record<string, string | number>) => {
      if (!configurationNumber) return;
      await getFinancingSimulation(configurationNumber, financingId, productKey, values);
    },
    [configurationNumber, financingId, getFinancingSimulation],
  );

  const onSaveSimulation = useCallback(
    async (product: FinancingProduct, values: Record<string, string | number>) => {
      if (!configurationNumber || !financingSimulation) return;
      await saveFinancingSimulation(
        configurationNumber,
        financingId,
        financingSimulation,
        product,
        values,
        financingDetails?.[FinancingDetailsFields.remarkTemplate],
      );

      resetFinancingSimulation();

      await getFinancingDetails(configurationNumber, financingId);

      const { response: newFinancingSimulation } = await getFinancingSimulation(
        configurationNumber,
        financingId,
        product['CDproductKey'],
        {},
      );
      if (newFinancingSimulation) {
        setActiveFinancingSimulation(newFinancingSimulation);
      }
    },
    [
      configurationNumber,
      financingDetails,
      financingId,
      financingSimulation,
      getFinancingDetails,
      getFinancingSimulation,
      resetFinancingSimulation,
      saveFinancingSimulation,
    ],
  );

  const onSelect = useCallback(async () => {
    if (!configurationNumber) return;

    await selectFinancingRecord(configurationNumber, financingId);
    await getFinancingList(configurationNumber);
  }, [configurationNumber, financingId, getFinancingList, selectFinancingRecord]);

  const isSelected = useMemo(() => {
    const item = financingDetails?.[FinancingDetailsFields.listItems]?.find(
      rec => String(rec[FinancingListItemFields.id]) === financingId,
    );
    if (!item) return true;
    return Boolean(item[FinancingListItemFields.isChosen]);
  }, [financingDetails, financingId]);

  return (
    <Layout withVerticalScrolling bg={false}>
      <Container size="md">
        <ScTabs
          centered
          destroyInactiveTabPane
          activeKey={activeTabKey}
          onChange={onTabChange}
        >
          {isFinancingCustomerFormFeatureEnabled && (
            <ScTabs.TabPane tab={t('FINANCING_CUSTOMER')} key={TabsKeys.customer}>
              <CustomerForm
                isDisabled={false}
                initialValues={initialValues?.[FinancingDetailsFields.customer]}
                defaultCountryCode={defaultCountryCode}
                onSubmit={values => {
                  handleSubmit({ [FinancingDetailsFields.customer]: values });
                }}
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}
          {isFinancingCarInfoFormFeatureEnabled && (
            <ScTabs.TabPane tab={t('FINANCING_CAR_INFO')} key={TabsKeys.carInfo}>
              <CarInfoForm
                isDisabled={false}
                initialValues={initialValues?.[FinancingDetailsFields.carInfo]}
                onSubmit={values => {
                  handleSubmit({ [FinancingDetailsFields.carInfo]: values });
                }}
                setIsTabLocked={setIsActiveTabLocked}
              />
            </ScTabs.TabPane>
          )}

          {isFinancingListItemFormFeatureEnabled && (
            <ScTabs.TabPane tab={t('FINANCING_TOTAL')} key={TabsKeys.total}>
              <Total
                values={initialValues?.[FinancingDetailsFields.total]}
                products={initialValues?.[FinancingDetailsFields.products]}
                calculationSimulation={financingSimulation ?? undefined}
                activeCalculationSimulation={activeFinancingSimulation ?? undefined}
                isCalculationSimulationLoading={
                  financingSimulationStatus === Status.Loading ||
                  financingDetailsStatus === Status.Loading
                }
                onSelect={onSelect}
                isSelected={isSelected}
                onProductChange={onProductChange}
                onUpdateSimulation={onUpdateSimulation}
                onSaveSimulation={onSaveSimulation}
              />
            </ScTabs.TabPane>
          )}
        </ScTabs>
      </Container>
    </Layout>
  );
};
