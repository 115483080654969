import {
  MachineListItemFields,
  TradeInListItemFields,
  TradeInVatTypeListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { CustomerFields, DocumentTemplate, Filter } from 'types/vendor';
import { Col, Row, Tooltip } from 'antd';
import { DATE_FORMAT, VAT } from 'common/constants';
import { Button } from 'components/button';
import { DatePicker } from 'components/form/DatePicker';
import FormField from 'components/form/formik/FormField';
import { Input } from 'components/form/Input';
import { useFeature } from 'context/feature/FeatureProvider';
import {
  TradeIn,
  TradeInBaseFields,
  TradeInFormFields,
  TradeInProviderValues,
  TradeInVatType,
} from 'context/tradeIn/TradeInProvider';
import { FormikProps, FormikProvider, useFormik, Form } from 'formik';
import { TFunction } from 'i18next';
import { useMediaQuery } from 'react-responsive';
import { sizes, theme } from 'common/theme';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual, omit } from 'utils';
import { notification } from 'utils/notification';
import { Status } from 'utils/types';
import * as Yup from 'yup';
import { Option, RequestResponseDetails } from '../../types/common';
import {
  Footer,
  ScButton,
  ScLinkIcon,
  ScMarginInfoIcon,
  ScMarginLabel,
  ScMarginWrapper,
  ScSearchMachineIcon,
  ScSelector,
  ScTextArea,
  ScTitle,
  ScTradeInDocumentsContainer,
  ScTradeInFieldsContainer,
  ScTradeInForm,
  ScTradeInFormContainer,
  ScTradeInHeader,
  ScTradeInTile,
  SubFooter,
} from './TradeInForm.styles';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import { InputNumber } from 'components/form/InputNumber';
import Layout from 'layout/Default/Layout';
import Container from 'components/container/Container';
import { TradeInSubmitAction } from './TradeInSubmitAction';
import { isFieldRequired } from 'utils/is-field-required';
import { useModalState } from 'hooks/useModalState';
import { SearchMachineModal, SearchMachineModalProps } from './SearchMachineModal';
import { useRelations } from 'context/relations/RelationsProvider';
import { useModelApi } from 'context/model/useModelApi';
import { DocumentItem, DocumentRelatedEntityCode } from 'context/document/types';
import Switcher from 'components/form/Switcher';
import { DocumentsList } from 'components/document/List/DocumentsList';
import { useDocumentsApi } from 'context/document/useDocumentsApi';
import { fillFormFromMachine, getNormalizedValues } from './utils';
import { InputNumberLocalized } from 'components/form/InputNumberLocalized';

const getTradeInVatOptionLabel = (vatType: TradeInVatType): string => {
  return (
    vatType[TradeInVatTypeListItemFields.name] +
    ' - ' +
    vatType[TradeInVatTypeListItemFields.percent] +
    '%'
  );
};

interface Props {
  tradeInContext: TradeInProviderValues;
  isConfigurationComplete: boolean;
  t: TFunction;
  getTradeInPDFTemplates(): Promise<void>;
  pdfTemplates: DocumentTemplate[];
  handleTradeInSendEmail(machineNumber: string): void;
}

export const TradeInForm: FC<Props> = ({
  tradeInContext,
  isConfigurationComplete,
  t,
  pdfTemplates,
  getTradeInPDFTemplates,
  handleTradeInSendEmail,
}) => {
  const { getDocumentsList } = useDocumentsApi();

  const [createdMachineNumber, setCreatedMachineNumber] = useState<string | undefined>();
  // if "true", then we can submit the form at once without displaying the template options (as there is only one)
  // if "false", then submission should be triggered on each template option action
  // currently, the 2 flows for submission can be seen, and this condition is used directly for that -
  // toggle submission logic
  const isSinglePDFTemplate = useMemo<boolean>(
    () => pdfTemplates.length <= 1,
    [pdfTemplates],
  );
  // should be set once on first submission attempt if some of the required fields are not filled
  const [shouldValidate, setShouldValidate] = useState<boolean>(false);

  const [documents, setDocuments] = useState<DocumentItem[]>([]);

  const {
    isCreate,
    createTradeIn,
    updateTradeIn,
    setTradeInTableComponent,
    calculateVariance,
    selectedTradeIn,
    tradeIns,
    editTradeIn,
    getInitialFields,
    loadTradeIns,
    getTradeInByMachineNumber,
    getVatTypesList,
    vatTypes,
  } = tradeInContext;

  const { isFeatureEnabled } = useFeature();
  const { relations } = useRelations();
  const { getModel } = useModelApi();

  const selectedTradeInData = useMemo<TradeIn | undefined>(() => {
    if (!selectedTradeIn?.machineNumber || isCreate) return;
    return getTradeInByMachineNumber(selectedTradeIn?.machineNumber);
  }, [getTradeInByMachineNumber, selectedTradeIn, isCreate]);

  const initialValues = useMemo<TradeInBaseFields>(() => {
    return isCreate ? getInitialFields(t) : (selectedTradeIn as TradeInBaseFields);
  }, [getInitialFields, isCreate, selectedTradeIn, t]);

  const isAllowAddFile = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddFile,
  });
  const isAllowAddLink = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddLink,
  });
  const isAllowCreate = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInAddNew,
  });
  const isAllowUpdate = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInUpdate,
  });
  const isAllowSendEmail = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInSendEmail,
  });
  const isAllowMargin = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInMargin,
  });
  const isAllowStandingValueField = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInStandingValueField,
  });
  const isAllowTradeInFirstRegistrationField = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInFirstRegistrationField,
  });
  const isAllowTradeInLastRegistrationField = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInLastRegistrationField,
  });
  const isAllowVarianceField = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInVarianceField,
  });
  const isAllowTradeInDescriptionFieldOptional = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInDescriptionFieldOptional,
  });
  const isAllowTradeInMachineNumberFieldOptional = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowTradeInMachineNumberFieldOptional,
  });

  const isUpToLg = useMediaQuery({ maxWidth: sizes.lg });

  const [searchMachineModalState, handleSearchMachineModal] = useModalState<
    SearchMachineModalProps,
    Record<string, never>,
    SearchMachineModalProps
  >({});

  const loadDocuments = useCallback(() => {
    if (!selectedTradeInData) return;
    getDocumentsList(
      DocumentRelatedEntityCode.tradeInDedicated,
      selectedTradeInData,
    ).then(({ response }) => {
      setDocuments(response ?? []);
    });
  }, [getDocumentsList, selectedTradeInData]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const handleOpenMachineSearch = useCallback(
    (setFieldValue: FormikProps<TradeInBaseFields>['setFieldValue']) => {
      const filter: Filter<MachineListItemFields> = [];
      const relationId = relations[0]?.customer?.[CustomerFields.id];

      if (relationId)
        filter.push({
          name: MachineListItemFields.customerNumber,
          value: relationId,
        });

      handleSearchMachineModal({
        filters: filter,
        onClose: () => handleSearchMachineModal(),
        onSubmit: records => {
          const [record] = records;
          if (record) fillFormFromMachine(setFieldValue, record);
        },
      });
    },
    [handleSearchMachineModal, relations],
  );

  useEffect(() => {
    if (tradeIns.length && createdMachineNumber) {
      editTradeIn(createdMachineNumber);
      setCreatedMachineNumber(undefined);
    }
  }, [tradeIns, createdMachineNumber, editTradeIn]);

  useEffect(() => {
    getTradeInPDFTemplates();
  }, [getTradeInPDFTemplates]);

  useEffect(() => {
    getVatTypesList();
  }, [getVatTypesList]);

  const onSubmit = useCallback(
    async (values: TradeInFormFields) => {
      const normalizedValues = getNormalizedValues(values);

      let response: RequestResponseDetails;

      if (isCreate) {
        response = await createTradeIn(normalizedValues);
        await loadTradeIns();
        setCreatedMachineNumber(values.machineNumber);
      } else {
        response = await updateTradeIn(normalizedValues);
        await loadTradeIns();
      }
      // get updated model to have updated prices in header
      await getModel();

      const { status, messageHandled } = response;

      if (!messageHandled) {
        notification.openByStatus(status, {
          [Status.Success]: t(
            isCreate ? 'TRADE_IN_CREATE_SUCCESS' : 'TRADE_IN_UPDATE_SUCCESS',
          ),
          [Status.Error]: t(isCreate ? 'TRADE_IN_CREATE_ERROR' : 'TRADE_IN_UPDATE_ERROR'),
        });
      }
    },
    [isCreate, createTradeIn, loadTradeIns, updateTradeIn, t, getModel],
  );

  const TradeInSchema = useMemo(() => {
    return Yup.object().shape<TradeInBaseFields>({
      actualValue: Yup.number(),
      purchaseValue: Yup.number(),
      brandName: Yup.string().required(t('FORM__REQUIRED')),
      modelName: Yup.string().required(t('FORM__REQUIRED')),
      machineNumber: isAllowTradeInMachineNumberFieldOptional
        ? Yup.string()
        : Yup.string().required(t('FORM__REQUIRED')),
      workingHours: Yup.number().nullable().required(t('FORM__REQUIRED')),
      description: isAllowTradeInDescriptionFieldOptional
        ? Yup.string()
        : Yup.string().required(t('FORM__REQUIRED')),
      constructedAtYear: Yup.number()
        .positive(t('FORM__REQUIRED'))
        .required(t('FORM__REQUIRED')),
      firstRegistrationAt: isAllowTradeInFirstRegistrationField
        ? Yup.string()
        : Yup.string().nullable().required(t('FORM__REQUIRED')),
      lastRegistrationAt: isAllowTradeInLastRegistrationField
        ? Yup.string()
        : Yup.string().nullable().required(t('FORM__REQUIRED')),
      attachments: Yup.string(),
      isVatEnabled: Yup.boolean(),
      vat: Yup.number(),
    });
  }, [
    isAllowTradeInDescriptionFieldOptional,
    isAllowTradeInFirstRegistrationField,
    isAllowTradeInLastRegistrationField,
    isAllowTradeInMachineNumberFieldOptional,
    t,
  ]);

  const shouldDisableFields = useMemo(
    () => !isCreate && isConfigurationComplete,
    [isCreate, isConfigurationComplete],
  );

  const vatTypesOptions = useMemo<Option[]>(() => {
    return vatTypes.map(vatType => ({
      label: getTradeInVatOptionLabel(vatType),
      value: vatType[TradeInVatTypeListItemFields.id],
    }));
  }, [vatTypes]);

  const handleTradeInViewAll = useCallback(
    (values: TradeInBaseFields): void => {
      // @todo: cleanup
      const currentConstructedAtYear = new Date(values.constructedAtYear).getFullYear();
      const initialConstructedAtYear = new Date(
        initialValues?.constructedAtYear,
      ).getFullYear();
      const isYearTheSame = currentConstructedAtYear === initialConstructedAtYear;
      const isClean =
        isEqual(
          omit(values, 'constructedAtYear'),
          omit(initialValues, 'constructedAtYear'),
        ) && isYearTheSame;
      if (isClean) {
        setTradeInTableComponent();
        return;
      }
      const confirmTradeInViewAll = window.confirm(t('DATA_MAYBE_LOST'));
      if (confirmTradeInViewAll) {
        setTradeInTableComponent();
      }
    },
    [initialValues, setTradeInTableComponent, t],
  );

  const submitActionLabel = useMemo<string | null>(() => {
    if (isCreate && isAllowCreate) return t('TRADE_IN_CREATE');

    if (!isCreate && isAllowUpdate) return t('TRADE_IN_UPDATE');

    return null;
  }, [isCreate, isAllowCreate, t, isAllowUpdate]);

  // https://github.com/jaredpalmer/formik/issues/3327
  // reason of usage FormikProvider below
  const formik = useFormik<TradeInBaseFields>({
    initialValues: initialValues ?? {},
    validationSchema: TradeInSchema,
    validateOnChange: false,
    validateOnBlur: true,
    enableReinitialize: true,
    // need it as a plug here, as submit button is outside of the form
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
  });

  const {
    values,
    errors,
    touched,
    setErrors,
    dirty,
    setFieldValue,
    handleBlur,
    validateForm,
  } = formik;

  const hasErrors = useMemo<boolean>(() => Boolean(Object.keys(errors).length), [errors]);

  return (
    <Layout>
      {searchMachineModalState && <SearchMachineModal {...searchMachineModalState} />}
      <Container size="lg">
        <ScTradeInFormContainer data-testid="trade-in-form-container">
          <ScTradeInFieldsContainer>
            <Row gutter={isUpToLg ? [20, 20] : 20}>
              <Col xs={24} xl={15}>
                <ScTradeInTile>
                  <ScTradeInHeader data-testid="add-tradein-form-header">
                    <ScTitle data-testid="add-tradein-form-title">
                      {t('TRADE_IN_DETAILS')}
                      <Tooltip title={t('TRADE_IN_FORM_FILL_ICON_DESCRIPTION')}>
                        <ScSearchMachineIcon
                          onClick={() => handleOpenMachineSearch(setFieldValue)}
                        />
                      </Tooltip>
                    </ScTitle>
                  </ScTradeInHeader>
                  <ScTradeInForm data-testid="add-tradein-form-body">
                    <FormikProvider value={formik}>
                      <Form>
                        <FormField
                          required={isFieldRequired(TradeInSchema, 'brandName')}
                          component={Input}
                          label={t('TRADE_IN_BRAND')}
                          data-testid="add-tradein-form-make-field"
                          name="brandName"
                          withErrors={Boolean(touched.brandName || shouldValidate)}
                          disabled={shouldDisableFields}
                          onBlur={handleBlur}
                        />
                        <Row justify="space-between" gutter={16}>
                          <Col span={12}>
                            <FormField
                              required={isFieldRequired(TradeInSchema, 'modelName')}
                              component={Input}
                              label={t('TRADE_IN_MODEL')}
                              data-testid="add-tradein-form-models-field"
                              name="modelName"
                              withErrors={Boolean(touched.modelName || shouldValidate)}
                              disabled={shouldDisableFields}
                              onBlur={handleBlur}
                              maxLength={40}
                            />
                          </Col>
                          <Col span={12}>
                            <FormField
                              required={isFieldRequired(TradeInSchema, 'machineNumber')}
                              component={Input}
                              label={t('TRADE_IN_SERIAL')}
                              data-testid="add-tradein-form-machine-number-field"
                              name="machineNumber"
                              withErrors={Boolean(
                                touched.machineNumber || shouldValidate,
                              )}
                              disabled={shouldDisableFields}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                        <Row justify="space-between" gutter={16}>
                          <Col span={12}>
                            <FormField
                              name="workingHours"
                              label={t('TRADE_IN_WORKING_HOURS')}
                              component={InputNumber}
                              data-testid="add-tradein-form-working-hours-field"
                              type="number"
                              required={isFieldRequired(TradeInSchema, 'workingHours')}
                              withErrors={Boolean(touched.workingHours || shouldValidate)}
                              step={1}
                              min={0}
                              disabled={shouldDisableFields}
                              onBlur={handleBlur}
                            />
                          </Col>
                          <Col span={12}>
                            <FormField
                              name="constructedAtYear"
                              label={t('TRADE_IN_CONSTRUCTED_AT_YEAR')}
                              component={DatePicker}
                              picker="year"
                              format="YYYY"
                              data-testid="add-tradein-form-constructed-at-year-field"
                              allowClear={false}
                              required={isFieldRequired(
                                TradeInSchema,
                                'constructedAtYear',
                              )}
                              withErrors={Boolean(
                                touched.constructedAtYear || shouldValidate,
                              )}
                              disabled={shouldDisableFields}
                              onBlur={handleBlur}
                            />
                          </Col>

                          {isAllowTradeInFirstRegistrationField && (
                            <Col span={12}>
                              <FormField
                                name="firstRegistrationAt"
                                label={t('TRADE_IN_FIRST_REGISTRATION_AT')}
                                component={DatePicker}
                                format={DATE_FORMAT}
                                allowClear={false}
                                required={isFieldRequired(
                                  TradeInSchema,
                                  'firstRegistrationAt',
                                )}
                                withErrors={Boolean(
                                  touched.firstRegistrationAt || shouldValidate,
                                )}
                                disabled={shouldDisableFields}
                                onBlur={handleBlur}
                              />
                            </Col>
                          )}

                          {isAllowTradeInLastRegistrationField && (
                            <Col span={12}>
                              <FormField
                                name="lastRegistrationAt"
                                label={t('TRADE_IN_LAST_REGISTRATION_AT')}
                                component={DatePicker}
                                format={DATE_FORMAT}
                                allowClear={false}
                                required={isFieldRequired(
                                  TradeInSchema,
                                  'lastRegistrationAt',
                                )}
                                withErrors={Boolean(
                                  touched.lastRegistrationAt || shouldValidate,
                                )}
                                disabled={shouldDisableFields}
                                onBlur={handleBlur}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row justify="space-between" gutter={16}>
                          <Col flex="auto">
                            <FormField
                              component={InputNumberLocalized}
                              label={t('TRADE_IN_PURCHASE_VALUE')}
                              required={isFieldRequired(TradeInSchema, 'purchaseValue')}
                              data-testid="add-tradein-form-purchase-value-field"
                              name="purchaseValue"
                              step={0.01}
                              min={0}
                              withErrors={Boolean(
                                touched.purchaseValue || shouldValidate,
                              )}
                              disabled={shouldDisableFields}
                              onBlur={handleBlur}
                              symbolsAfterDecimal={2}
                            />
                          </Col>
                          {isAllowStandingValueField && (
                            <Col flex="auto">
                              <FormField
                                component={InputNumberLocalized}
                                label={t('TRADE_IN_STANDING_VALUE')}
                                required={isFieldRequired(TradeInSchema, 'actualValue')}
                                data-testid="add-tradein-form-actual-value-field"
                                name="actualValue"
                                step={0.01}
                                min={0}
                                withErrors={Boolean(
                                  touched.actualValue || shouldValidate,
                                )}
                                disabled={shouldDisableFields}
                                onBlur={handleBlur}
                              />
                            </Col>
                          )}
                        </Row>
                        {isAllowVarianceField && (
                          <FormField
                            value={
                              typeof values.actualValue === 'number' ||
                              typeof values.purchaseValue === 'number'
                                ? calculateVariance(
                                    values.actualValue,
                                    values.purchaseValue,
                                  )
                                : ''
                            }
                            component={Input}
                            name="variance"
                            disabled
                            label={t('TRADE_IN_VARIANCE')}
                            style={{
                              input: {
                                color: theme.colors.black,
                              },
                              label: {
                                color: theme.discountColor,
                              },
                            }}
                            data-testid="add-tradein-form-variance-field"
                            type="number"
                            required={isFieldRequired(TradeInSchema, 'variance')}
                            step={0.01}
                            withErrors={false}
                          />
                        )}
                        <Row justify="space-between" gutter={16}>
                          <Col span={12}>
                            <FormField
                              required={isFieldRequired(TradeInSchema, 'description')}
                              component={ScTextArea}
                              label={t('TRADE_IN_DESCRIPTION')}
                              data-testid="add-tradein-form-description-field"
                              name="description"
                              withErrors={Boolean(touched.description || shouldValidate)}
                              disabled={shouldDisableFields}
                              rows={3}
                              onBlur={handleBlur}
                            />
                          </Col>
                          <Col span={12}>
                            <FormField
                              component={ScTextArea}
                              required={isFieldRequired(TradeInSchema, 'attachments')}
                              label={t('TRADE_IN_ATTACHMENTS')}
                              data-testid="add-tradein-form-attachments-field"
                              name="attachments"
                              withErrors={Boolean(touched.attachments || shouldValidate)}
                              disabled={shouldDisableFields}
                              rows={3}
                              onBlur={handleBlur}
                            />
                          </Col>
                        </Row>
                        {isAllowMargin && (
                          <Row gutter={16}>
                            <Col span={12}>
                              <ScMarginWrapper>
                                <ScMarginLabel>
                                  {t('TRADE_IN_MARGIN')}
                                  <Tooltip title={t('TRADE_IN_MARGIN_DESCRIPTION')}>
                                    <ScMarginInfoIcon />
                                  </Tooltip>
                                </ScMarginLabel>
                                <FormField
                                  component={Switcher}
                                  checked={values.isVatEnabled}
                                  data-testid="add-tradein-form-margin-field"
                                  name="isVatEnabled"
                                  onChange={event => {
                                    setFieldValue(
                                      TradeInListItemFields.isVatEnabled,
                                      event.target.value,
                                    );
                                    if (event.target.value) {
                                      setFieldValue(TradeInListItemFields.vat, VAT);
                                    }
                                  }}
                                  withErrors={Boolean(
                                    touched.isVatEnabled || shouldValidate,
                                  )}
                                  disabled={shouldDisableFields}
                                  required={isFieldRequired(
                                    TradeInSchema,
                                    'isVatEnabled',
                                  )}
                                />
                              </ScMarginWrapper>
                            </Col>
                            <Col span={12}>
                              <FormField
                                component={ScSelector}
                                label={t('TRADE_IN_VAT')}
                                data-testid="add-tradein-form-vat-field"
                                name="vat"
                                required={isFieldRequired(TradeInSchema, 'vat')}
                                options={vatTypesOptions}
                                withErrors={Boolean(touched.vat || shouldValidate)}
                                disabled={shouldDisableFields || values.isVatEnabled}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </FormikProvider>
                  </ScTradeInForm>
                </ScTradeInTile>
              </Col>
              <Col xs={24} xl={9}>
                <ScTradeInTile>
                  <ScTradeInHeader>
                    <ScTitle data-testid="add-tradein-documents-title">
                      {t('TRADE_IN_DOCUMENTS')}
                    </ScTitle>
                  </ScTradeInHeader>
                  <ScTradeInDocumentsContainer>
                    {selectedTradeInData && (
                      <DocumentsList
                        documents={documents}
                        relatedEntityCode={DocumentRelatedEntityCode.tradeInDedicated}
                        relatedEntity={selectedTradeInData}
                        isAddFileVisible={isAllowAddFile}
                        isAddLinkVisible={isAllowAddLink}
                        reloadDocuments={loadDocuments}
                        testidSuffix={'tradein'}
                      />
                    )}
                  </ScTradeInDocumentsContainer>
                </ScTradeInTile>
              </Col>
            </Row>
          </ScTradeInFieldsContainer>
          <Footer data-testid="add-tradein-form-footer">
            <SubFooter data-testid="add-tradein-form-subfooter">
              <ScButton
                onClick={() => handleTradeInViewAll(values)}
                data-testid="add-tradein-form-view-table-btn"
              >
                {t('TRADE_IN_VIEW_ALL')}
                <ScLinkIcon />
              </ScButton>
              {submitActionLabel && (
                <TradeInSubmitAction
                  pdfTemplates={pdfTemplates}
                  onSubmit={async pdfTemplate => {
                    const validationErrors = await validateForm(values);
                    if (Object.keys(validationErrors).length) {
                      setErrors(validationErrors);
                      setShouldValidate(true);
                      return;
                    }

                    formik.setSubmitting(true);
                    await onSubmit({ ...values, printTemplate: pdfTemplate });
                    formik.setSubmitting(false);
                  }}
                  onMenuOpen={async () => {
                    const validationErrors = await validateForm(values);
                    if (Object.keys(validationErrors).length) {
                      setErrors(validationErrors);
                      setShouldValidate(true);
                      return false;
                    }
                    return true;
                  }}
                  onButtonClick={async () => {
                    if (!isSinglePDFTemplate) return;

                    const validationErrors = await validateForm(values);
                    if (Object.keys(validationErrors).length) {
                      setErrors(validationErrors);
                      setShouldValidate(true);
                      return;
                    }

                    formik.setSubmitting(true);
                    await onSubmit({ ...values, printTemplate: pdfTemplates[0] });
                    formik.setSubmitting(false);
                  }}
                  isValid={!hasErrors}
                  disabled={Boolean(
                    isConfigurationComplete || !dirty || (hasErrors && shouldValidate),
                  )}
                  t={t}
                  data-testid={
                    isCreate
                      ? 'add-tradein-form-create-btn'
                      : 'add-tradein-form-update-item'
                  }
                >
                  {submitActionLabel}
                </TradeInSubmitAction>
              )}
              {isAllowSendEmail && (
                <Button
                  variant="primary"
                  disabled={!values.machineNumber || dirty || isConfigurationComplete}
                  data-testid="tradein-page-form-send-email"
                  onClick={() => handleTradeInSendEmail(values.machineNumber)}
                >
                  {t('TRADE_IN_SEND_EMAIL')}
                </Button>
              )}
            </SubFooter>
          </Footer>
        </ScTradeInFormContainer>
      </Container>
    </Layout>
  );
};
