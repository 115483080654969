import styled, { css } from 'styled-components';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';

export const ScCircleOutlinedWrapper = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translateX(calc(5em)) scale(1);
  width: 5em;
  transition: all 500ms;
  z-index: 1;

  &.open {
    transform: translateX(0em) scale(1);
  }

  &.deep {
    z-index: 2000;
  }
`;

const circleStyles = css<{ $canShow: boolean }>`
  position: absolute;
  font-size: 3em;
  color: ${({ theme }) => theme.mainColor};
  cursor: pointer;
  opacity: ${({ $canShow }) => ($canShow ? '1' : '0')};
  transition: visibility 0s linear 300ms, opacity 300ms;
  visibility: ${({ $canShow }) => ($canShow ? 'visible' : 'hidden')};
`;

export const ScDownCircleOutlined = styled(DownCircleOutlined)`
  ${circleStyles}
  bottom: 1em;
  right: 50%;
  transform: translateX(50%);
`;

export const ScUpCircleOutlined = styled(UpCircleOutlined)`
  ${circleStyles}
  bottom: 3em;
  right: 50%;
  transform: translateX(50%);
`;
