import React from 'react';
import { ScRotatingLoader, ScRotatingLoaderAbsoluteWrap } from './RotatingLoader.styles';

type Props = {
  className?: string;
};

export const RotatingLoader: React.FC<Props> = ({ className }) => {
  return <ScRotatingLoader className={className} />;
};

export const RotatingLoaderAbsoluteWrap = ScRotatingLoaderAbsoluteWrap;
