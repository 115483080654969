import {
  DocumentLeveledRelationType,
  ModelFields,
  ModelRecordFields,
  ModelTradeInFields,
  TradeInFields,
  DocumentRelation,
  Model,
  DocumentListItem,
} from 'types/vendor';
import { DocumentRelationLeveled } from '@hypercharge/xdms-client/lib/_backendMethods/common/documentRelationLeveled';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import {
  DocumentSource,
  DocumentRelatedEntity,
  DocumentRelatedEntityCode,
  DocumentRelatedEntitySettings,
} from './types';

export const DocumentRelatedEntitySettingsList: DocumentRelatedEntitySettings[] = [
  {
    entityCode: DocumentRelatedEntityCode.configuration,
    documentSource: DocumentSource.CONFIGURATION,
    availabilityFeatureFlag: '',
    modalsTKey: '',
    documentsPageTKey: 'DOCUMENTS_CONFIGURATION_TITLE',
    nameField: '',
    idField: '',
    defaultTypeCategoriesPicklistCode: 's-mach-cfg-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: null,
    assembleRelation_default: (configurationNumber: number): DocumentRelation => {
      return { configuration: configurationNumber };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (configurationNumber: number): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.configuration,
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
    ): string => {
      return `configurator/${configurationNumber}/${name}`.toLocaleLowerCase();
    },
  },
  {
    entityCode: DocumentRelatedEntityCode.option,
    documentSource: DocumentSource.OPTIONS,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsOptions,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_OPTIONS_TITLE',
    documentsPageTKey: 'DOCUMENT_OPTIONS_TITLE',
    nameField: ModelRecordFields.name,
    idField: ModelRecordFields.ID,
    defaultTypeCategoriesPicklistCode: 's-mach-opt-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.options];
      return items?.find(item => item[ModelRecordFields.ID] === id);
    },
    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[ModelRecordFields.documentsRelationType],
          code: entity?.[ModelRecordFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.options,
        recordId: entity?.[ModelRecordFields.ID],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[ModelRecordFields.ID]
      }/${name}`.toLocaleLowerCase();
    },
  },
  {
    entityCode: DocumentRelatedEntityCode.accessory,
    documentSource: DocumentSource.ACCESSORIES,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsAccessories,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_ACCESSORIES_TITLE',
    documentsPageTKey: 'DOCUMENT_ACCESSORIES_TITLE',
    nameField: ModelRecordFields.name,
    idField: ModelRecordFields.ID,
    defaultTypeCategoriesPicklistCode: 's-mach-opt-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.accessories];
      return items?.find(item => item[ModelRecordFields.ID] === id);
    },

    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[ModelRecordFields.documentsRelationType],
          code: entity?.[ModelRecordFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.accessories,
        recordId: entity?.[ModelRecordFields.ID],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[ModelRecordFields.ID]
      }/${name}`.toLocaleLowerCase();
    },
  },
  {
    entityCode: DocumentRelatedEntityCode.tAc,
    documentSource: DocumentSource.TERMS_AND_CONDITIONS,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsTermsAndConditions,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_TERMS_AND_CONDITIONS_TITLE',
    documentsPageTKey: 'DOCUMENT_TERMS_AND_CONDITIONS_TITLE',
    nameField: ModelRecordFields.name,
    idField: ModelRecordFields.ID,
    defaultTypeCategoriesPicklistCode: 's-mach-opt-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.termsAndConditions];
      return items?.find(item => item[ModelRecordFields.ID] === id);
    },
    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[ModelRecordFields.documentsRelationType],
          code: entity?.[ModelRecordFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: 'T&C' as DocumentLeveledRelationType,
        recordId: entity?.[ModelRecordFields.ID],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[ModelRecordFields.ID]
      }/${name}`.toLocaleLowerCase();
    },
  },
  {
    entityCode: DocumentRelatedEntityCode.tradeIn,
    documentSource: DocumentSource.TRADE_INS,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsTradeIns,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_TRADE_INS_TITLE',
    documentsPageTKey: 'DOCUMENT_TRADE_INS_TITLE',
    nameField: ModelTradeInFields.machineId,
    idField: ModelTradeInFields.machineId,
    defaultTypeCategoriesPicklistCode: 's-mach-sell-tov-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.tradeIns];
      return items?.find(item => item[ModelTradeInFields.machineId] === id);
    },
    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[ModelRecordFields.documentsRelationType],
          code: entity?.[ModelRecordFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.tradeIn,
        recordId: entity?.[ModelTradeInFields.machineId],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[ModelTradeInFields.machineId]
      }/${name}`.toLocaleLowerCase();
    },
  },
  {
    entityCode: DocumentRelatedEntityCode.tradeInDedicated,
    documentSource: DocumentSource.TRADE_INS,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsTradeIns,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_TRADE_INS_TITLE',
    documentsPageTKey: 'DOCUMENT_TRADE_INS_TITLE',
    nameField: TradeInFields.description,
    idField: TradeInFields.machineNumber,
    defaultTypeCategoriesPicklistCode: 's-mach-sell-tov-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.tradeIns];
      return items?.find(item => item[ModelTradeInFields.machineId] === id);
    },
    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[TradeInFields.documentsRelationType],
          code: entity?.[TradeInFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.tradeIn,
        recordId: entity?.[TradeInFields.machineNumber],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[TradeInFields.machineNumber]
      }/${name}`.toLocaleLowerCase();
    },
  },
  /** We don't have documents on packages. But, just in case, have this plug */
  {
    entityCode: DocumentRelatedEntityCode.package,
    documentSource: DocumentSource.PACKAGES,
    availabilityFeatureFlag: GlobalFeaturesFlagsFields.allowDocumentsOptions,
    modalsTKey: 'QUOTE_EMAIL_MODAL__DOCUMENTS_OPTIONS_TITLE',
    documentsPageTKey: 'DOCUMENT_OPTIONS_TITLE',
    nameField: ModelRecordFields.name,
    idField: ModelRecordFields.ID,
    defaultTypeCategoriesPicklistCode: 's-mach-opt-CDcat-doc',

    getTypeCategoriesPicklistCode: function (documentRecord?: DocumentListItem): string {
      return (
        documentRecord?.typeCategoryPicklistCode ?? this.defaultTypeCategoriesPicklistCode
      );
    },
    findModelItem: (model: Model, id: string): DocumentRelatedEntity => {
      const items = model[ModelFields.packages];
      return items?.find(item => item[ModelRecordFields.ID] === id);
    },
    assembleRelation_default: (
      _: number,
      entity: DocumentRelatedEntity,
    ): DocumentRelation => {
      return {
        values: {
          type: entity?.[ModelRecordFields.documentsRelationType],
          code: entity?.[ModelRecordFields.documentsRelationCode],
        },
      };
    },
    assembleRelation_details: (document: DocumentListItem): DocumentRelation => {
      return {
        values: {
          type: document.relationType,
          code: document.relationId,
        },
      };
    },
    assembleRelation_leveled: (
      configurationNumber: number,
      entity?: DocumentRelatedEntity,
    ): DocumentRelationLeveled => {
      return {
        configuration: configurationNumber,
        type: DocumentLeveledRelationType.options,
        recordId: entity?.[ModelRecordFields.ID],
      };
    },
    assembleExternalStorageFilePath: (
      configurationNumber: number,
      name: string,
      entity?: DocumentRelatedEntity,
    ): string => {
      return `configurator/${configurationNumber}/${
        entity?.[ModelRecordFields.ID]
      }/${name}`.toLocaleLowerCase();
    },
  },
];

export const DocumentRelatedEntitySettingsSelectors = {
  getByEntityCode: (
    valueToSearch: DocumentRelatedEntityCode,
  ): DocumentRelatedEntitySettings => {
    const settings = DocumentRelatedEntitySettingsList.find(
      ({ entityCode }) => valueToSearch === entityCode,
    );
    if (!settings)
      throw new Error(
        `Looked for setting by unknown entityCode: ${valueToSearch}. Normally it shouldn't happen. Check the code.`,
      );

    return settings;
  },
  getBySource: (valueToSearch: DocumentSource): DocumentRelatedEntitySettings => {
    const settings = DocumentRelatedEntitySettingsList.find(
      ({ documentSource }) => valueToSearch === documentSource,
    );
    if (!settings)
      throw new Error(
        `Looked for setting by unknown documentSource: ${valueToSearch}. Normally it shouldn't happen. Check the code.`,
      );

    return settings;
  },
};
