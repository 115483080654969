import * as Sentry from '@sentry/react';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,

  enabled:
    process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging',
  ignoreErrors: [
    // These errors can be safely ignored
    // https://github.com/juggle/resize-observer/issues/103
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    // Expiration of token is not an issue
    'Your session has expired',
    'Request aborted',
  ],
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
