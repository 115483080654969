import React, { FC, useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader } from 'components/modal';
import {
  CustomerFields,
  MessageCodeValues,
  MessageFields,
  ReserveStockMachineInput,
} from 'types/vendor';
import { useTranslation } from 'react-i18next';
import { ScModalContent } from './ReserveModal.styles';
import { useMachineApi } from 'context/machine/useMachineApi';
import { Col, Row } from 'antd';
import { RotatingLoader, RotatingLoaderAbsoluteWrap } from 'components/RotatingLoader';
import { useFormik, FormikProvider, Form } from 'formik';
import FormField from 'components/form/formik/FormField';
import { isFieldRequired } from 'utils/is-field-required';
import { DatePicker } from 'components/form/DatePicker';
import { DATE_FORMAT } from 'common/constants';
import { InputNumber } from 'components/form/InputNumber';
import { OptionWithKey } from 'types';
import { getSchema } from './schema';
import useCurrentLanguageCode from 'hooks/useCurrentLanguageCode';
import { useRelations } from 'context/relations/RelationsProvider';
import { get } from 'utils';
import moment from 'moment';
import Selector from 'components/form/Selector';
import { TextArea } from 'components/form/TextArea';
import { Button } from 'components/button/Button';
import { notification, NotificationType } from 'utils/notification';

type Values = Omit<ReserveStockMachineInput, 'machineNumber' | 'language' | 'customerId'>;

interface Props {
  machineCode: string;
  onClose: () => void;
}
export type ReserveModalState = Props;
const ReserveModal: FC<Props> = ({ onClose, machineCode }) => {
  const { t } = useTranslation();
  const { getLocationOptions, reserveStockMachine } = useMachineApi();
  const { relations } = useRelations();
  const currentLanguageCode = useCurrentLanguageCode();

  const [locationOptionsList, setLocationOptionsList] = useState<OptionWithKey[]>([]);
  const [isLoadingLocationOptionsList, setIsLoadingLocationOptionsList] =
    useState<boolean>(false);
  const [isCreatingReservation, setIsCreatingReservation] = useState<boolean>(false);

  useEffect(() => {
    let on = true;

    setIsLoadingLocationOptionsList(true);
    const fn = () => {
      getLocationOptions()
        .then(options => {
          if (on) {
            setLocationOptionsList(options ?? []);
          }
        })
        .finally(() => {
          if (on) {
            setIsLoadingLocationOptionsList(false);
          }
        });
    };

    const timeoutHandle = setTimeout(fn, 500);
    return () => {
      clearTimeout(timeoutHandle);
      on = false;
    };
  }, [getLocationOptions]);

  const isDisabled = isCreatingReservation;

  const schema = getSchema(t);

  const onSubmit = useCallback(
    async (values: Values) => {
      const customerId = get(relations, [0, 'customer', CustomerFields.id]);

      if (!customerId) {
        notification.open({
          type: NotificationType.error,
          message: 'Driver was not selected. Select driver first.',
        });
        return;
      }

      setIsCreatingReservation(true);
      const { messageHandled, response } = await reserveStockMachine({
        ...values,
        language: currentLanguageCode.system,
        customerId: customerId,
        machineNumber: machineCode,
      });
      if (!messageHandled) {
        const status = response?.message.some(
          item => item[MessageFields.code] === MessageCodeValues.error,
        )
          ? NotificationType.error
          : NotificationType.success;

        notification.open({
          type: status,
          message: response?.message.length
            ? response.message
            : t(
                status === NotificationType.success
                  ? 'PRICE_LIST_STOCK_RESERVE_MODAL_SUCCESS'
                  : 'PRICE_LIST_STOCK_RESERVE_MODAL_ERROR',
              ),
        });
      }

      setIsCreatingReservation(false);

      onClose();
    },
    [currentLanguageCode.system, machineCode, onClose, relations, reserveStockMachine, t],
  );

  const formProps = useFormik<Values>({
    initialValues: {
      date: moment(new Date()).format(DATE_FORMAT),
      days: 1,
      location: '',
    },
    onSubmit: onSubmit,
    validationSchema: schema,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
  });

  return (
    <Modal variant="sm" visible={true} onCancel={onClose} center>
      <ModalHeader $textAlign="center">
        {t('PRICE_LIST_STOCK_RESERVE_MODAL_TITLE')}
      </ModalHeader>

      <ScModalContent>
        {isCreatingReservation && (
          <RotatingLoaderAbsoluteWrap>
            <RotatingLoader />
          </RotatingLoaderAbsoluteWrap>
        )}

        <FormikProvider value={formProps}>
          <Form>
            <Row gutter={[15, 0]}>
              <Col span={24}>
                <FormField
                  name={'date'}
                  component={DatePicker}
                  format={DATE_FORMAT}
                  required={isFieldRequired(schema, 'date')}
                  disabled
                  label={t('PRICE_LIST_STOCK_RESERVE_MODAL_DATE')}
                />
              </Col>
              <Col span={24}>
                <FormField
                  name={'days'}
                  component={InputNumber}
                  required={isFieldRequired(schema, 'days')}
                  disabled={isDisabled}
                  label={t('PRICE_LIST_STOCK_RESERVE_MODAL_DAYS')}
                />
              </Col>
              <Col span={24}>
                <FormField
                  name={'location'}
                  component={Selector}
                  loading={isLoadingLocationOptionsList}
                  options={locationOptionsList}
                  required={isFieldRequired(schema, 'location')}
                  disabled={isDisabled}
                  label={t('PRICE_LIST_STOCK_RESERVE_MODAL_LOCATION')}
                />
              </Col>
              <Col span={24}>
                <FormField
                  name={'comment'}
                  component={TextArea}
                  required={isFieldRequired(schema, 'comment')}
                  disabled={isDisabled}
                  label={t('PRICE_LIST_STOCK_RESERVE_MODAL_COMMENT')}
                />
              </Col>
            </Row>

            <Row justify="end">
              <Col>
                <Button htmlType="submit" disabled={isDisabled} variant="highlighted">
                  {t('SAVE')}
                </Button>
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </ScModalContent>
    </Modal>
  );
};

export default ReserveModal;
