import { InputNumberProps } from 'antd/lib/input-number';
import React, { FC, useRef, ChangeEvent, useCallback, FocusEvent } from 'react';
import { ScInputNumber, ScInputNumberContainer } from './InputNumber.styles';
import classnames from 'classnames';
import { getTitle } from 'utils/get-title';
import { isNil, toNumber } from 'utils';
import classNames from 'classnames';

type NumberInputElement = Omit<HTMLInputElement, 'value'> & { value: number };

interface Props
  extends Omit<InputNumberProps, 'onChange' | 'onBlur' | 'parser' | 'formatter'> {
  label?: string;
  className?: string;
  onChange?(event: ChangeEvent<NumberInputElement>): void;
  onBlur?(event: FocusEvent<NumberInputElement, Element>): void;
  parser?: (...args: Parameters<NonNullable<InputNumberProps['parser']>>) => number;
  formatter?: (value: number | undefined, rawValue: string | undefined) => string;
  bordered?: boolean;
}

export const InputNumber: FC<Props> = ({
  onChange,
  max,
  min,
  name,
  onBlur,
  parser,
  formatter,
  value,
  bordered = true,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // used to presrve divider, or other special characters removed while parsing
  const displayValueMetaRef = useRef<{ value: number; displayValue: string }>();

  const localOnChange = useCallback(
    (value: string | number | null | undefined) => {
      const event = {
        target: {
          name: name,
          value: toNumber(value),
        },
      } as ChangeEvent<NumberInputElement>;

      onChange?.(event);
    },
    [name, onChange],
  );

  const localOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement, Element>) => {
      let newValue = parser?.(event.target.value) ?? toNumber(event.target.value);

      // part of 'max', 'min' interception
      // duplicated it as some values are taken from 'onBlur' event
      // so better be sure to have correct value just after used made some change
      if (!isNil(max) && newValue >= max) {
        newValue = max;
        displayValueMetaRef.current = undefined;
        localOnChange(newValue);
      }

      if (!isNil(min) && newValue <= min) {
        newValue = min;
        displayValueMetaRef.current = undefined;
        localOnChange(newValue);
      }

      const sentEvent = {
        ...event,
        target: {
          ...event.target,
          value: newValue,
        },
        currentTarget: {
          ...event.currentTarget,
          value: newValue,
        },
      };

      onBlur?.(sentEvent);
    },
    [localOnChange, max, min, onBlur, parser],
  );

  return (
    <ScInputNumberContainer
      className={classNames(props.className, {
        borderless: !bordered,
      })}
      data-testid="input-number-container"
    >
      {props.label && (
        <label
          className={classnames({
            'form-input-disabled': props.disabled,
          })}
          style={props.style}
          data-testid="input-number-label"
        >
          {getTitle(props.label, { required: props.required })}
        </label>
      )}
      <ScInputNumber
        data-testid="input-number"
        ref={inputRef}
        onFocus={() => inputRef.current?.select()}
        {...props}
        min={undefined}
        max={undefined}
        // default 'max', 'min' behavior cuts off over part from actual value and shows max value in input instead actual value
        // here we override that behavior by intercepting onChange event
        // so 'max', 'min' prop doesn't affect render of value, only onChange event
        onChange={localOnChange}
        onBlur={localOnBlur}
        value={value}
        formatter={value => {
          let resultValue = value;

          if (displayValueMetaRef.current?.value === toNumber(value)) {
            resultValue = displayValueMetaRef.current?.displayValue;
          }

          return formatter?.(toNumber(value), String(resultValue)) ?? String(resultValue);
        }}
        parser={value => {
          // proxying empty value to 0, as empty causes validation to go mad
          const stringValue: string = value || '0';

          const numberValue = parser?.(stringValue) ?? toNumber(stringValue);

          displayValueMetaRef.current = { value: numberValue, displayValue: stringValue };

          return numberValue;
        }}
      />
    </ScInputNumberContainer>
  );
};
