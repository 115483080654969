import styled from 'styled-components';
import { Button } from 'components/button';
import { Row } from 'antd';
import Container from 'components/container/Container';

export const ScHeader = styled.div`
  height: 3.125em;
  box-sizing: content-box;
  padding: 1em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.softPeach};
`;

export const ScAddNewButton = styled(Button)`
  font-size: 1.125em;
  width: 100%;
`;

export const ScFooter = styled.footer`
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ScSaveButton = styled(Button)`
  display: block;
`;

export const ScHeaderContainer = styled(Container)`
  height: 100%;
`;

export const ScHeaderRow = styled(Row)`
  height: 100%;
`;
