import * as Yup from 'yup';
import { ReserveStockMachineInput } from 'types/vendor';
import { TFunction } from 'i18next';

export const getSchema = (t: TFunction) => {
  return Yup.object().shape<
    Partial<Omit<ReserveStockMachineInput, 'machineNumber' | 'language' | 'customerId'>>
  >({
    date: Yup.string().required(t('FORM__REQUIRED')),
    days: Yup.number().required(t('FORM__REQUIRED')),
    location: Yup.string().required(t('FORM__REQUIRED')),
    comment: Yup.string(),
  });
};
