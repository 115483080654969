import { useCallback, useMemo } from 'react';
import { Status } from 'utils/types';
import { RequestResponseDetails } from '../../types/common';
import { notification } from '../../utils/notification';
import { useQuery } from 'context/router/UrlQueryProvider';
import { Configuration, ConfigurationFields } from 'types/vendor';
import { DocumentsTreeData } from 'hooks/useDocumentsTree/types';
import { get } from 'utils';
import { DocumentRelatedEntityCode } from 'context/document/types';
import { prepareDocumentsForEntity } from './utils';
import { WEBHOOK_PROXY_URL } from 'common/constants';
import { useSelector } from 'react-redux';
import { authSelectors } from 'store';

export interface Values {
  isWebhookEnabled: boolean;
  sendDetailsToWebhook(
    configuration: Configuration,
    documentsTree: DocumentsTreeData,
  ): Promise<RequestResponseDetails>;
}

type DataContract = {
  machCfg: {
    NRconfig: number | null;
    NRcust: number | null;
    NRcust2: number | null;
    NRcust3: number | null;
    NRcontact: number | null;
    CDstatus: string | null;
    CDtype: string | null;
    NRidProject: number | null;
  };
  urlQuery: {
    ProcessMetaId: string;
    ProcessRunId: string;
  };
  ConfigurationDocuments: { id: number; url: string; name: string; selected: boolean }[];
  OptionDocuments: { id: number; url: string; name: string; selected: boolean }[];
  AccessoryDocuments: { id: number; url: string; name: string; selected: boolean }[];
  TradeInDocuments: { id: number; url: string; name: string; selected: boolean }[];
  PackageDocuments: { id: number; url: string; name: string; selected: boolean }[];
  TermsAndConditionsDocuments: {
    id: number;
    url: string;
    name: string;
    selected: boolean;
  }[];
};

export const useHyperportalConfigurationDetailsWebhook = (): Values => {
  const { initialQueryValues } = useQuery();

  const baseURL = useSelector(authSelectors.getBaseUrl);
  const authToken = useSelector(authSelectors.getAuthToken);

  const isWebhookEnabled = useMemo(() => {
    return Boolean(
      initialQueryValues.hyperportalConfigurationDetailsWebhook?.returnDataUrl,
    );
  }, [initialQueryValues.hyperportalConfigurationDetailsWebhook?.returnDataUrl]);

  const sendDetailsToWebhook = useCallback<Values['sendDetailsToWebhook']>(
    async (configuration, documentsTree) => {
      let result: RequestResponseDetails;

      try {
        const queryParams = initialQueryValues.hyperportalConfigurationDetailsWebhook;
        if (!queryParams) {
          throw new Error('query param not provided, check setup');
        }
        if (
          !queryParams.authPass ||
          !queryParams.authUser ||
          !queryParams.processMetaId ||
          !queryParams.processRunId ||
          !queryParams.returnDataUrl
        ) {
          throw new Error('query param not provided, check setup');
        }

        if (!configuration) {
          throw new Error('configuration not provided, check setup');
        }

        if (!WEBHOOK_PROXY_URL) {
          throw new Error('WEBHOOK_PROXY_URL not provided, check setup');
        }
        if (!baseURL) {
          throw new Error('baseURL not provided, check setup');
        }
        if (!authToken) {
          throw new Error('authToken not provided, check setup');
        }

        const data: DataContract = {
          machCfg: {
            NRconfig: get(configuration, ConfigurationFields.id, null),
            NRcust: get(configuration, ConfigurationFields.customerNumber, null),
            NRcust2: get(configuration, ConfigurationFields.customerNumber2, null),
            NRcust3: get(configuration, ConfigurationFields.customerNumber3, null),
            NRcontact: get(configuration, ConfigurationFields.contactNumber, null),
            CDstatus: get(configuration, ConfigurationFields.statusCode, null),
            CDtype: get(configuration, 'CDtype', null),
            NRidProject: get(configuration, ConfigurationFields.projectId, null),
          },
          urlQuery: {
            ProcessMetaId: queryParams.processMetaId,
            ProcessRunId: queryParams.processRunId,
          },
          ConfigurationDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.configuration,
          ),
          OptionDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.option,
          ),
          AccessoryDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.accessory,
          ),
          TradeInDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.tradeIn,
          ),
          PackageDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.package,
          ),
          TermsAndConditionsDocuments: prepareDocumentsForEntity(
            documentsTree,
            DocumentRelatedEntityCode.tAc,
          ),
        };

        const url = new URL(WEBHOOK_PROXY_URL);
        url.searchParams.append('proxyUrl', queryParams.returnDataUrl);
        url.searchParams.append('xdmsUrl', baseURL);
        url.searchParams.append('xdmsAuthToken', authToken);

        await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              'Basic ' + btoa(`${queryParams.authUser}:${queryParams.authPass}`),
          },
          method: 'POST',
          body: JSON.stringify(data),
        });

        result = {
          status: Status.Success,
          messageHandled: false,
        };
      } catch (e) {
        notification.requestError(e);
        result = { status: Status.Error, messageHandled: true };
      }
      return result;
    },
    [authToken, baseURL, initialQueryValues.hyperportalConfigurationDetailsWebhook],
  );

  return useMemo(
    () => ({
      isWebhookEnabled,
      sendDetailsToWebhook,
    }),
    [isWebhookEnabled, sendDetailsToWebhook],
  );
};
