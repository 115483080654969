import { DocumentListItem_Status_Values } from '@hypercharge/xdms-client/lib/types';

const documentStatus__mapping__selectionValues: Record<
  DocumentListItem_Status_Values,
  // [isExternal, isInternal]
  [boolean, boolean]
> = {
  [DocumentListItem_Status_Values.both]: [true, true],
  [DocumentListItem_Status_Values.internal]: [false, true],
  [DocumentListItem_Status_Values.external]: [true, false],
  [DocumentListItem_Status_Values.empty]: [false, false],
};

export const getDocumentStatusByValues = (
  values: [boolean, boolean],
): DocumentListItem_Status_Values => {
  const statusFindResult = Object.entries(documentStatus__mapping__selectionValues).find(
    ([_, [isExternal, isInternal]]) =>
      isExternal === values[0] && isInternal === values[1],
  );

  if (!statusFindResult) return DocumentListItem_Status_Values.empty;

  return statusFindResult[0] as DocumentListItem_Status_Values;
};

export const getStatusValuesByDocumentStatus = (
  value: DocumentListItem_Status_Values,
): [boolean, boolean] => {
  return (
    documentStatus__mapping__selectionValues[value] ||
    documentStatus__mapping__selectionValues[DocumentListItem_Status_Values.empty]
  );
};
