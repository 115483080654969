import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Filter,
  ConfigurationListItem,
  ConfigurationListItemFields,
  ConfigurationStatusListItemFields,
} from '@hypercharge/xdms-client/lib/types';
import { useTranslation } from 'react-i18next';
import { useContact } from 'context/contact/ContactProvider';
import { Status } from 'utils/types';
import { get } from 'utils';
import { FILTER_SIGNS } from 'common/constants';
import { SearchModal } from '../SearchModal/SearchModal';
import { useColumns } from '../../pages/ConfigurationList/use-columns';
import { columnKeys } from '../../pages/ConfigurationList/ConfigurationList';
import { useConfiguration } from '../../context/configuration/ConfigurationProvider';
import { SelectedFiltersItemsList_Filters } from '../filters/FiltersList';
import { ConfigurationFields } from 'types/vendor';

interface Props {
  onClose(): void;
  onSubmit(records: ConfigurationListItem[]): void;
  initialValues?: ConfigurationListItem[];
  /** Used just to update local `filters` state. */
  filters?: Filter<ConfigurationListItemFields>;
  onAddNew?(): void;
  extraButtons?: ReactNode;
}
/** Big pieces of logic are copied from ConfigurationList. @todo do away with it */
export const SearchConfigurationModal: FC<Props> = ({
  onClose,
  onSubmit,
  onAddNew,
  initialValues,
  filters: externalFilters,
  extraButtons,
}) => {
  const { t } = useTranslation();

  const { searchConfigurations, configurationStatusCodes } = useConfiguration();
  const { status: contactStatus } = useContact();

  const [configurations, setConfigurations] = useState<ConfigurationListItem[]>([]);
  const [filters, setFilters] = useState<
    SelectedFiltersItemsList_Filters<ConfigurationListItemFields>
  >([]);
  const [filterStatusCodeList, setFilterStatusCodeList] = useState<string[]>([]);
  const [configurationStatus, setConfigurationStatus] = useState<Status>(Status.Idle);

  useEffect(() => {
    setFilters(externalFilters ?? []);
  }, [externalFilters]);

  useEffect(() => {
    const timeout = setTimeout(async () => {
      setConfigurationStatus(Status.Loading);
      const list = await searchConfigurations(filters);
      setConfigurationStatus(Status.Success);
      setConfigurations(list);
    }, 100);
    return () => clearTimeout(timeout);
  }, [searchConfigurations, filters]);

  const isLoading = useMemo(() => {
    return [configurationStatus, contactStatus].includes(Status.Loading);
  }, [configurationStatus, contactStatus]);

  const handleReset = useCallback(async dataIndex => {
    if (dataIndex === ConfigurationListItemFields.status) {
      setFilterStatusCodeList([]);
    }

    setFilters(filters => filters.filter(filter => filter.name !== dataIndex));
  }, []);

  const handleSearch = useCallback(
    async (selectedKeys, confirm, dataIndex) => {
      confirm({ closeDropdown: true });
      const preparedFilters = filters.filter(filter => get(filter, 'name') !== dataIndex);
      const filterValue = get(selectedKeys, 0, '');

      if (filterValue) {
        const sign =
          dataIndex === ConfigurationListItemFields.id
            ? FILTER_SIGNS.EQUAL
            : FILTER_SIGNS.INCLUDES;

        preparedFilters.push({
          name: dataIndex,
          value: filterValue,
          sign: sign,
        });
      }

      setFilters(preparedFilters);
    },
    [filters],
  );

  const onStatusChange = useCallback((values: string[]) => {
    setFilterStatusCodeList(values);
  }, []);

  const onStatusSubmit = useCallback(
    (selectedKeys, confirm) => {
      const updatedFilters = filters.filter(
        filter => get(filter, 'name') !== ConfigurationListItemFields.status,
      );

      const displayValue = filterStatusCodeList.map(statusCode => {
        const status = configurationStatusCodes?.find(
          item => item[ConfigurationStatusListItemFields.code] === statusCode,
        );
        return status?.[ConfigurationStatusListItemFields.name] ?? t('STATUS_UNKNOWN');
      });

      updatedFilters.push({
        name: ConfigurationListItemFields.status,
        value: filterStatusCodeList,
        displayValue: displayValue.join(', '),
        sign: FILTER_SIGNS.EQUAL,
      });
      confirm({ closeDropdown: true });
      setFilters(updatedFilters);
    },
    [filterStatusCodeList, configurationStatusCodes, filters, t],
  );

  /** Copied from ConfigurationList */
  const tableTitles = useMemo(() => {
    return {
      [columnKeys.ID]: t('CONFIG_LIST_NUMBER'),
      [columnKeys.NAME]: t('CONFIG_LIST_NAME'),
      [columnKeys.CUSTOMER_NAME]: t('CONFIG_LIST_CUSTOMER_INFO'),
      [columnKeys.CUSTOMER_CONTACT]: t('CONFIG_LIST_CUSTOMER_CONTACT'),
      [columnKeys.DEALER]: t('CONFIG_LIST_DEALER'),
      [columnKeys.END_USER]: t('CLIENTS_END_USER'), // enduser
      [columnKeys.MODEL]: t('MODELS'),
      [columnKeys.ACTUAL_PRICE]: t('CONFIG_LIST_ACTUAL_VALUE'),
      [columnKeys.STATUS]: t('STATUS'),
      [columnKeys.CREATION_DATE]: t('CREATION_DATE'),
      [columnKeys.CREATED_FROM]: t('FROM'),
      [columnKeys.CREATED_TO]: t('TO'),
      [columnKeys.CREATED_BY]: t('CREATED_BY'),
      [columnKeys.CHANGED_BY]: t('CHANGED_BY'),
      [columnKeys.TOKEN]: t('TOKEN'),
      [ConfigurationFields.referenceNumber]: t('SESSION_ID'),
      [columnKeys.ACTIONS]: t('ACTIONS'),
    };
  }, [t]);

  const tableColumns = useColumns({
    tableTitles,
    filters,
    handleSearch,
    handleReset,
    configurationStatusCodes,
    filterStatusCodeList,
    onStatusSubmit,
    onStatusChange,
    excludedColumns: [
      columnKeys.ACTIONS,
      columnKeys.CHANGED_BY,
      columnKeys.CREATION_DATE,
      columnKeys.ACTUAL_PRICE,
      columnKeys.TOKEN,
    ],
  });

  // @todo: antd@5
  return (
    <SearchModal
      testNamePrefix="configuration"
      onClose={onClose}
      initialValue={initialValues}
      onAddNew={onAddNew}
      tableTitles={tableTitles}
      tableColumns={tableColumns as any}
      tableData={configurations}
      tableDataIdField={ConfigurationListItemFields.id}
      filters={filters}
      onFilterClearAll={() => setFilters([])}
      onFilterClearOne={handleReset}
      isLoading={isLoading}
      extraButtons={extraButtons}
      onSubmit={records => {
        onSubmit(records as ConfigurationListItem[]);
        onClose();
      }}
    />
  );
};
