import { Tooltip } from 'antd';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Tabs from 'components/Tabs';
import { useRelations } from 'context/relations/RelationsProvider';
import { useStep } from 'context/step/StepProvider';
import { useStreaming } from 'context/streaming/StreamingProvider';
import Layout from 'layout/Default/Layout';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ContainerSize } from 'common/theme';
import Container from 'components/container/Container';
import { SwapRelationsModal } from './modals/SwapRelationsModal';
import { CopyRelationModal } from './modals/CopyRelationModal';
import { useFeature } from 'context/feature/FeatureProvider';
import { ConfigurationRelation } from 'context/relations/types';
import { getTitle } from 'utils/get-title';
import { Greeting } from './Greeting';
import { RelationItem } from './RelationItem';
import {
  ScCustomerForm,
  ScSwapIcon,
  ScCopyIcon,
  ScTabContainer,
  ScItemWrap,
} from './RelationsList.styles';
import { StreamingEventType } from 'context/streaming/types';

export const RelationsList: FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const { relations, getArePrevRelationsFilled } = useRelations();
  const { handleNextStep } = useStep();
  const { isFeatureEnabled } = useFeature();
  const { sendMessage } = useStreaming();

  const defaultActiveTab = useMemo(() => relations[0]?.id, [relations]);

  const [showCustomerForm, setShowCustomerForm] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(defaultActiveTab);
  const [containerSize, setContainerSize] = useState<ContainerSize | undefined>('lg');

  const [showSwapRelationsModal, setShowSwapRelationsModal] = useState<boolean>(false);
  const [showCopyRelationsModal, setShowCopyRelationsModal] = useState<boolean>(false);

  const isAllowSwapButton = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RelationSwapEnabled,
  });
  const isAllowCopyButton = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.RelationCopyEnabled,
  });

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  const getRelationTabTitle = useCallback(
    (relation: ConfigurationRelation) => {
      const isShowActions = relation.id === activeTab && Boolean(relation.customer);

      return (
        <>
          {getTitle(t(relation.titleTKey), { required: relation.mandatory })}

          {isAllowSwapButton && isShowActions && (
            <Tooltip placement="top" title={t('SWAP_RELATION')}>
              {/* span is wrapper for passing ref from Tooltip. Icon doesn't support it */}
              <span>
                <ScSwapIcon
                  onClick={() => setShowSwapRelationsModal(true)}
                  disabled={relation.mandatory && !relation.customer}
                />
              </span>
            </Tooltip>
          )}

          {isAllowCopyButton && isShowActions && (
            <Tooltip placement="top" title={t('COPY_RELATION')}>
              <ScCopyIcon
                onClick={() => setShowCopyRelationsModal(true)}
                disabled={!relation.customer}
              />
            </Tooltip>
          )}
        </>
      );
    },
    [activeTab, isAllowSwapButton, isAllowCopyButton, t],
  );

  const handleGoForward = useCallback(
    (idx: number) => {
      const plainIdx = Math.min(idx + 1, relations.length);
      const isCurrentStepLast = plainIdx === relations.length;

      if (isCurrentStepLast) {
        handleNextStep();
      } else {
        const nextRelation = relations[plainIdx];
        nextRelation && setActiveTab(nextRelation.id);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    [handleNextStep, relations],
  );

  useEffect(() => {
    const isAnyRelationFilled = relations.some(
      ({ customer, primaryContact, additionalContacts }) => {
        return (
          Boolean(customer) ||
          Boolean(primaryContact) ||
          Boolean(additionalContacts.length)
        );
      },
    );

    setShowCustomerForm(isAnyRelationFilled);
  }, [relations]);

  useEffect(() => {
    sendMessage({
      type: StreamingEventType.CHANGE_SCREEN,
      data: {
        screen: 'standby',
      },
    });
  }, [sendMessage]);

  if (!showCustomerForm) {
    return (
      <Layout>
        <Greeting onClick={() => setShowCustomerForm(true)} t={t} />
      </Layout>
    );
  }

  return (
    <Layout
      withVerticalScrolling
      verticalScrollingReset={activeTab}
      verticalScrollingRefEl="relations-list-ref"
    >
      <Container size={containerSize}>
        <ScCustomerForm
          className="relations-list-ref"
          data-testid="clients-page-form-wrapper"
        >
          {relations.length > 1 ? (
            <Tabs
              className="huge-tabs"
              centered
              data-testid="clients-page-tabs"
              activeKey={activeTab}
              onTabClick={setActiveTab}
              destroyInactiveTabPane
            >
              {relations.map(relation => (
                <Tabs.TabPane
                  key={relation.id}
                  tab={getRelationTabTitle(relation)}
                  disabled={!getArePrevRelationsFilled(relation)}
                  data-testid={`clients-page-relation-tab__${relation.titleTKey}`}
                >
                  <ScTabContainer
                    data-testid={`clients-page-relation-tab-container__${relation.titleTKey}`}
                  >
                    <RelationItem
                      relation={relation}
                      goForward={() => handleGoForward(relation.idx)}
                      goFarForward={handleNextStep}
                      setContainerSize={setContainerSize}
                    />
                  </ScTabContainer>
                </Tabs.TabPane>
              ))}
            </Tabs>
          ) : (
            <ScItemWrap
              data-testid={`clients-page-relation-tab-container__${relations[0].titleTKey}`}
            >
              <RelationItem
                relation={relations[0]}
                goForward={() => handleGoForward(relations[0].idx)}
                goFarForward={handleNextStep}
                setContainerSize={setContainerSize}
              />
            </ScItemWrap>
          )}
        </ScCustomerForm>
      </Container>

      <SwapRelationsModal
        isOpen={showSwapRelationsModal}
        activeRelationId={activeTab}
        relations={relations}
        onClose={() => setShowSwapRelationsModal(false)}
      />

      <CopyRelationModal
        isOpen={showCopyRelationsModal}
        activeRelationId={activeTab}
        relations={relations}
        onClose={() => setShowCopyRelationsModal(false)}
        onCopy={(_, targetRelationId) => setActiveTab(targetRelationId)}
      />
    </Layout>
  );
};
