import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useRef } from 'react';
import {
  ScCircleOutlinedWrapper,
  ScUpCircleOutlined,
  ScDownCircleOutlined,
} from './VerticalScrolling.styles';
import { sizes } from 'common/theme';
import isNil from 'lodash.isnil';
import classNames from 'classnames';

// The component should rely on shared state
// in order to avoid layering of components
// in case multiple modals are opened
// or when similar layering is being experienced
const ID_STORAGE: number[] = [];
let CURRENT_ID: number | null = null;

type VerticalScrollingProps = PropsWithChildren<{
  refEl: string | HTMLElement | null;
  refWrapper: string | HTMLElement | null | Window;
  reset?: string | number;
}>;

const getCurrentNode = (
  ref: string | HTMLElement | null | Window,
): HTMLElement | null | Window => {
  if (typeof ref === 'string') {
    const nodes = Array.from(document.querySelectorAll(`.${ref}`));
    return nodes[nodes.length - 1] as HTMLElement;
  }
  return ref;
};

const isWindow = (ref: HTMLElement | null | Window): ref is Window => {
  return Boolean(!isNil((ref as Window)?.document));
};

export const VerticalScrolling: FC<VerticalScrollingProps> = props => {
  const [id, setId] = useState<number | null>(null);
  const ref = useRef<HTMLElement | null>(null);
  const refWrapper = useRef<HTMLElement | null | Window>(null);

  const [canShowUpScroller, setCanShowUpScroller] = useState<boolean>(false);
  const [canShowDownScroller, setCanShowDownScroller] = useState<boolean>(false);
  const [yCord, setYCord] = useState<number>(window.screen.height);

  const isLgAndWider = useMediaQuery({ minWidth: sizes.md });

  const handleScrollTo = useCallback((top: number) => {
    if (!refWrapper.current || !ref.current) return;
    refWrapper.current.scrollTo({
      top,
      behavior: 'smooth',
    });
  }, []);

  const handleClickUp = useCallback(() => {
    const top = yCord - 2 * window.screen.height;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.requestIdleCallback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestIdleCallback((deadline: IdleDeadline) => {
        if (deadline.timeRemaining() > 0) {
          handleScrollTo(top);
        }
      });
      return;
    }

    handleScrollTo(top);
  }, [handleScrollTo, yCord]);

  const handleClickDown = useCallback(() => {
    const top = yCord;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.requestIdleCallback) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      requestIdleCallback((deadline: IdleDeadline) => {
        if (deadline.timeRemaining() > 0) {
          handleScrollTo(top);
        }
      });
      return;
    }

    handleScrollTo(top);
  }, [handleScrollTo, yCord]);

  useEffect(() => {
    const el = getCurrentNode(props.refEl);
    // window can not be used as inner ref
    if (el) ref.current = el as HTMLElement;

    const elWrapper = getCurrentNode(props.refWrapper);
    if (elWrapper) refWrapper.current = elWrapper;
  }, [props.refEl, props.refWrapper, ref]);

  useEffect(() => {
    if (!refWrapper.current || !ref.current) return;

    const handler = () => {
      if (!refWrapper.current || !ref.current) return;
      let currentlyScrolledY = window.innerHeight;
      // "scrollTop" does not exist on window
      if (isWindow(refWrapper.current)) {
        currentlyScrolledY += document.documentElement.scrollTop;
      } else {
        currentlyScrolledY += refWrapper.current.scrollTop;
      }
      const totalScrollY = ref.current.scrollHeight;
      setCanShowUpScroller(
        window.screen.height < currentlyScrolledY && window.screen.height <= totalScrollY,
      );
      setCanShowDownScroller(currentlyScrolledY < totalScrollY);
      setYCord(currentlyScrolledY);
    };

    const listener = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.requestIdleCallback) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        requestIdleCallback((deadline: IdleDeadline) => {
          if (deadline.timeRemaining() > 0) {
            handler();
          }
        });
        return;
      }

      handler();
    };

    refWrapper.current.addEventListener('scroll', listener, false);

    if (CURRENT_ID) {
      ID_STORAGE.unshift(CURRENT_ID);
      CURRENT_ID += 1;
    } else {
      if (ID_STORAGE.length) {
        const nextId = ID_STORAGE.shift() as number;
        CURRENT_ID = nextId;
      } else {
        CURRENT_ID = 1;
      }
    }

    setId(CURRENT_ID);

    return () => {
      if (!refWrapper.current || !ref.current) return;
      setCanShowUpScroller(false);
      setCanShowDownScroller(false);
      refWrapper.current.removeEventListener('scroll', listener);
      const nextId = ID_STORAGE.shift();
      CURRENT_ID = typeof nextId === 'number' ? nextId : null;
    };
  }, []);

  useEffect(() => {
    if (!refWrapper.current || !ref.current) return;
    refWrapper.current.dispatchEvent(new CustomEvent('scroll'));
  }, [canShowDownScroller, canShowUpScroller, props.reset]);

  const isOpened = useMemo(() => {
    return id === CURRENT_ID && (canShowUpScroller || canShowDownScroller);
  }, [canShowDownScroller, canShowUpScroller, id]);

  return (
    <>
      {props.children}
      {!isLgAndWider && (
        <ScCircleOutlinedWrapper
          className={classNames({
            open: isOpened,
            deep: Boolean(ID_STORAGE.length),
          })}
        >
          <ScUpCircleOutlined onClick={handleClickUp} $canShow={canShowUpScroller} />
          <ScDownCircleOutlined
            onClick={handleClickDown}
            $canShow={canShowDownScroller}
          />
        </ScCircleOutlinedWrapper>
      )}
    </>
  );
};
